import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from "axios"
import VueClipBoard from 'vue-clipboard2'
import './assets/style.css'

Vue.use(VueClipBoard);
Vue.config.productionTip = false;
Vue.prototype.$axios=axios;
axios.defaults.baseURL="http://api.music.suours.com:25565"

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
