<template>
    <v-app>
        <v-main>
            <v-toolbar
                dense
                elevation="1"
            >
                <v-toolbar-title>Ourmusic</v-toolbar-title>
                <!--
                <v-spacer></v-spacer>
                <v-btn icon><v-icon>mdi-heart</v-icon></v-btn>
                <v-btn icon><v-icon>mdi-cog</v-icon></v-btn>
                -->
            </v-toolbar>
            <br/>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <!--搜索栏-->
                    <v-card
                        id="form"
                        dense
                        elevation="1"
                    >
                        <v-form>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="11">
                                    <v-text-field
                                    append-icon="mdi-magnify"
                                    @click:append="search"
                                    @keyup.enter="search"
                                    label="搜索"
                                    v-model="tag"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="6">
                                    <v-select
                                    :items="platforms"
                                    item-text="name"
                                    item-value="id"
                                    v-model="currentPlatform"
                                    label="平台"
                                    filled
                                    >
                                    </v-select>
                                </v-col>
                                <!--右侧占位符，用作和输入框对齐-->
                                <v-col cols="5"></v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <audio
                        controls
                        style="width:100%"
                        v-show="isPlaying"
                        ref="audio"
                    >
                        <source :src="url" type="audio/mpeg"/>
                    </audio>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <v-alert
                        dismissible
                        v-bind:type="alertType"
                        v-model="showAlert"
                        v-bind:color="alertBoxColor"
                    >
                        {{this.alertText}}
                    </v-alert>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="10">
                    <!--搜索结果-->
                    <v-card
                        dense
                        elevation="1"
                        id="search_result"
                    >
                        <v-simple-table v-show="showResult" fixed-header>
                            <template v-slot:default>
                                <tbody id="result_list">
                                    <tr
                                        v-for="song in searchResult"
                                        :key="song.id"
                                    >
                                        <td>
                                            <v-tooltip bottom color="primary">
                                                <template v-slot:activator="{on,attrs}">
                                                    <v-btn
                                                        v-on:click="play(song)"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon
                                                    >
                                                        <v-icon>mdi-play</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>播放</span>
                                            </v-tooltip>
                                        </td>
                                        <td>
                                            {{song.name.length>25?song.name.substr(0,22)+"...":song.name}}
                                            <br/>
                                            <span style="font-size:12px;color:#333333">{{song.singer.length>20?song.singer.substr(0,17)+"...":song.singer}}</span>
                                        </td>
                                        <td class="text-right">
                                            <v-tooltip bottom color="primary">
                                                <template v-slot:activator="{on,attrs}">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="copy(song)"
                                                        icon
                                                    >
                                                        <v-icon>mdi-clipboard</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>复制点歌口令</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-main>
    </v-app>
</template>
<script>
"use strict";
export default {
    name: 'App',
    components: {  

    },
    data: () => ({
        platforms:[
            {
                id:'N',
                name:"网易云"
            }
        ],//搜索平台
        showResult:false,//结果是否显示（默认值false）
        searchResult:[],//搜索结果
        currentPlatform:null,//当前平台
        tag:null,//搜索标签
        alertType:"error",//提示框类型
        alertText:null,//提示框文本
        showAlert:false,//是否显示提示框
        alertBoxColor:null,//提示框颜色
        progressPercent:0,//播放进度（%）
        currentSong:{
            platform:null,
            id:null,
            name:null,
            singer:null
        },//本地当前歌曲
        isPlaying:false,//是否正在播放
        url:null,//歌曲URL
        apiurl:"https://api.music.suours.com"//后端api
    }),
    methods:{
        alertBox:function(text,type){//提示框
            let boxColors={
                error:"red",
                success:"green",
                info:"blue",
                warning:"yellow"
            };
            this.alertType=type;
            this.alertText=text;
            this.showAlert=true;
            this.alertBoxColor=boxColors[type];
        },
        search:function(){//搜索
            this.showResult=false;
            this.$axios.get(this.apiurl+"/search",{
                params:{
                    p:this.currentPlatform,
                    q:encodeURI(this.tag)
                }
            })
            .then((response)=>{
                if(response.data.code!=200){
                    this.alertBox("出错啦，请重试","error");
                    return;
                }
                this.searchResult=[];
                let s=null;
                if(response.data.result.songCount==0){
                    this.alertBox("未搜索到相关内容","info");
                    return;
                }
                for(let i=0;i<response.data.result.songs.length;i++){
                    s=response.data.result.songs[i];
                    let song={
                        id:s.id,
                        name:s.name,
                        singer:"",
                        platform:this.currentPlatform
                    };
                    for(let j=0;j<s.artists.length;j++){
                        song.singer=song.singer+s.artists[j].name+' ';
                    }
                    this.searchResult.push(song);
                }
                this.showResult=true;
            })
            .catch((error)=>{
                this.alertBox("出错啦，请重试","error");
                console.log(error);
            });
        },
        play:function(song){//播放指定歌曲
            this.$axios.get(this.apiurl+"/geturl",{
                params:{
                    p:song.platform,
                    id:song.id
                }
            })
            .then((response)=>{
                if(response.data.status!=200){
                    if(response.data.status==423){
                        this.alertBox("出错啦，可能为会员歌曲或无版权","error");
                        return;
                    }
                    else if(response.data.status==404){
                        this.alertBox("该歌曲已下架","error");
                        return;
                    }
                    this.alertBox("出错啦，请重试","error");
                    return;
                }
                if(response.data.url){
                    this.url=response.data.url;
                    this.currentSong=song;
                    this.isPlaying=true;
                    this.$nextTick(()=>{
                        this.$refs.audio.src=this.url;
                        this.$refs.audio.play();
                    })
                }
                else{
                    this.alertBox("无法获取歌曲链接，可能为会员歌曲或无版权","error");
                }
            })
            .catch((error)=>{
                this.alertBox("出错啦，请重试","error");
                console.log(error);
            });
        },
        copy:function(song){//复制点歌口令
            this.$axios.get(this.apiurl+"/geturl",{
                params:{
                    p:song.platform,
                    id:song.id
                }
            })
            .then((response)=>{
                if(response.data.status!=200){
                    if(response.data.status==423){
                        this.alertBox("出错啦，可能为会员歌曲或无版权","error");
                        return;
                    }
                    else if(response.data.status==404){
                        this.alertBox("该歌曲已下架","error");
                        return;
                    }
                    this.alertBox("出错啦，请重试","error");
                    return;
                }
                if(response.data.url){
                    let msg="点歌#"+btoa(song.platform+"#"+song.id);
                    this.$copyText(msg)
                    .then(()=>{
                        this.alertBox("复制成功！粘贴至“我们的书山学海”公众号即可点播（需要经过人工审核）苹果用户请手动复制口令："+msg,"success");
                    },(e)=>{
                        this.alertBox("出错啦，请重试","error");
                        console.log(e);
                    })
                }
                else{
                    this.alertBox("无法获取歌曲链接，可能为会员歌曲或无版权","error");
                }
            })
            .catch((error)=>{
                this.alertBox("出错啦，请重试","error");
                console.log(error);
            });
        }
    },
    created(){
        document.title="Ourmusic";
        this.currentPlatform=this.platforms[0].id;
        let d=new Date();
        if(d.getDay()==4){
            console.log("KFC Crazy Thursday needs ￥50");
        }
    }
};
</script>